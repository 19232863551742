import { Entry } from "contentful";

import { useServerSideProps } from "~/contexts/server-side-props";
import Editorials from "~/types/editorials";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import productUtils from "~/utils/product-utils";

import { CarouselWrapper } from "../carousel-utils/carousel-utils";
import ProductCarouselWrapper from "./product-carousel-wrapper";

type Props = {
  entry: Entry<unknown>;
};

export default function ProductCarousel({ entry }: Props) {
  const { serverSideProps } = useServerSideProps();
  assertEditorialType<Editorials.ProductCarousel>(entry, "productCarousel");
  const productReferenceFields = entry.fields.products?.fields;
  const isAlgoliaCarousel = productReferenceFields && Object.hasOwn(productReferenceFields, "type");
  const hideCarousel = isAlgoliaCarousel
    ? serverSideProps?.selected
      ? productUtils.isGiftCard(serverSideProps?.selected) || productUtils.isVirtualProduct(serverSideProps?.selected)
      : false
    : false;

  if (!isActiveEntry(entry) || hideCarousel || !entry.fields?.products?.fields) return null;
  return (
    <CarouselWrapper entry={entry}>
      <ProductCarouselWrapper entry={entry} />
    </CarouselWrapper>
  );
}
