import { Entry } from "contentful";
import dynamic from "next/dynamic";
import { useMemo } from "react";

import Editorials from "~/types/editorials";
import { assertEditorialType } from "~/utils/editorial-utils";

type Props = {
  entry: Entry<unknown>;
};
export default function ProductCarouselWrapper({ entry }: Props) {
  const ProductReferenceComponent = useMemo(() => {
    assertEditorialType<Editorials.ProductCarousel>(entry, "productCarousel");
    const productReferenceFields = entry.fields.products.fields;
    if (!!productReferenceFields && !Object.hasOwn(productReferenceFields, "type")) {
      const ProductCarouselStatic = dynamic(() => import("./product-carousel-static"));
      return (
        <ProductCarouselStatic
          listScope={entry.sys.id}
          enableLoop={!!entry.fields.enableLoop}
          productReferenceFields={
            productReferenceFields as Editorials.ProductsReferenceById | Editorials.ProductsReferenceBySet
          }
        />
      );
    } else {
      const ProductCarouselAlgolia = dynamic(() => import("./product-carousel-algolia"));
      return (
        <ProductCarouselAlgolia
          listScope={entry.sys.id}
          enableLoop={!!entry.fields.enableLoop}
          productReferenceFields={productReferenceFields as Editorials.ProductsAlgoliaRecommender}
        />
      );
    }
  }, [entry]);
  return <>{ProductReferenceComponent}</>;
}
